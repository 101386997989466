<div class="tr-modal-wrapper">
    <div class="modal fade show d-block tr-modal vino-coin-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <h4 class="mb-0 font-bold">Congratulation!</h4>
                    <h4 class="mb-4 font-bold">You won {{ earnVcCount }} VCoins!</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="vino-coin">
                                <img class="vino-coin-image" src="../../../../assets/img/vino-coin-image.svg" alt="vino coin image">
                                <div class="vino-coin-text d-flex flex-column font-bold">
                                    <span class="number">{{ earnVcCount }}</span>
                                    <span class="text">VC</span>
                                </div>
                            </div>
                            <div class="next-challenge">
                                <div class="challenge-text font-bold">
                                    <span>{{ earnVcDescription }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-left">
                            <img class="level-up-glass" src="../../../../assets/img/level-up-glasses.svg">
                        </div>
                    </div>
                    <button type="button" class="btn tr-btn-primary text-uppercase mt-4" (click)="closePopup()">Ok, Great!!!</button>
                </div>
            </div>
        </div>
    </div>
    <div class="backdrop"></div>
</div>