/* eslint-disable lines-between-class-members */
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import Amplify, { Auth } from 'aws-amplify';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css'],
})
export default class ShareComponent implements OnInit {
  pageUrl = '';
  copyLink = false;
  copyBtnText = 'Copy';
  showLink = true;
  user: any = {};
  referralCount: number = 0;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.pageUrl = `${window.location.origin}/registration`;
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      this.generateShareUrl()
    });
  }

  onShareClick(): void {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then((user) => {
      this.user = user;
      this.generateShareUrl(true);
    })
    this.copyLink = !this.copyLink;
  }

  generateShareUrl(byClick = false){
    this.pageUrl = `${window.location.origin}/registration?reff=${this.user.username}`;
    if (this.user.attributes.hasOwnProperty('custom:referralCount')) {
      this.referralCount = Number(this.user.attributes['custom:referralCount']);
      if (Number(this.user.attributes['custom:referralCount']) >= 10) {
        this.pageUrl = `${window.location.origin}/registration`;
        (byClick) ? this.toastService.showWarning("You have reached the referral limit!") : '';
      }
    }
  }

  onLinkCopy(): void {
    this.showLink = false;
    const copyText: any = document.querySelector('#copyTextInput');
    copyText.select();
    document.execCommand('copy');
    this.copyBtnText = 'Copied';
    setTimeout(() => {
      this.showLink = true;
      this.copyBtnText = 'Copy';
      this.copyLink = false;
    }, 2000);
  }

  onClickedOutside(): void {
    this.copyLink = false;
  }
}
