import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { StorageService } from '../core/services/storage/storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class UserDataService {
  user: any;
  getUserAccess$ = new Subject();
  userDataChange$ = new Subject();
  userLevelUp$ = new Subject();
  userLevelsInfo$ = new Subject();
  userEarnVc$ = new Subject();

  constructor(
    private data: AuthenticationService,
    private storageService: StorageService
  ) {
    this.data.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  setUserData(data: any): void {
    this.user = data;
  }
  getUserData(): any {
    return this.user;
  }

  setLanguage(langCode: string): void {
    this.storageService.setLocalStorage('language', langCode);
  }
  getLanguage(): any {
    return this.storageService.getLocalStorage('language');
  }

  setUserAccessInfo(data: any): void {
    this.storageService.setLocalStorage('userAccessInfo', data, true);
    this.userDataChange$.next(data);
  }
  getUserAccessInfo(): any {
    return this.storageService.getLocalStorage('userAccessInfo', true);
  }

  setUserLevelUp(data: any): void {
    this.userLevelUp$.next(data);
  }

  setUserLevelsInformation(data: any): void {
    this.userLevelsInfo$.next(data);
  }

  showUserVcEarnInfo(data: any): void {
    this.userEarnVc$.next(data);
  }
}
