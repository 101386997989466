<div class="container">
    <div class="forgot-password-wrapper">
        <div id="forgotPassword" class="p-4 my-3 allFormBox">
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onFPSubmit()">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <mat-form-field appearance="fill">
                            <mat-label for="password">{{ 'login.email_address' | translate }}</mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error *ngIf="submitted && forgotPasswordDetails.email.errors" class="error-msg">
                                <div *ngIf="forgotPasswordDetails.email.errors.required">{{ 'login.email_required_warning' | translate }}</div>
                                <div *ngIf="forgotPasswordDetails.email.errors.email">{{ 'login.valid_email_msg' | translate }}</div>
                                <div *ngIf="forgotPasswordDetails.email?.hasError('validEmailWarning')">{{ 'login.valid_email_warning' | translate }}</div>
                                <div *ngIf="forgotPasswordDetails.email?.hasError('AttemptExceededWarning')">{{ 'login.attempt_exceeded_warning' | translate }}</div>
                                <div *ngIf="forgotPasswordDetails.email?.hasError('emailVerifyAlert')">{{ 'login.email_verify_alert' | translate }}</div>
                            </mat-error>
                        </mat-form-field>
                        <small id="passwordHelp" class="form-text text-muted">{{ 'login.reset_code_email_msg' | translate }}</small>
                    </div>
                </div>

                <div>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 btn-position">
                            <button type="submit" class="btn tr-btn-primary">{{ 'login.submit' | translate }}</button>
                            <button type="reset" routerLink="/login" class="btn tr-btn-secondary cancelBtn">{{ 'login.cancel' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>