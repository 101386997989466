import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public sendEvent(eventName: string, eventParams: Record<string, any>): void {
    if ((window as any).gtag) {
      (window as any).gtag('event', eventName, eventParams);
      console.warn('Google Analytics event is successfully trigger.');
    } else {
      console.warn('Google Analytics event is not trigger. - ' , eventName);
    }
  }
}
