import { Injectable, Renderer2, RendererFactory2, Inject  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PayPalService {
  private renderer: Renderer2;
  private clientId = 'AVLrZ6gg3945rf4Xw6d7bTJGEW4WkiP32fO01K-bMYPPsuxruwDfwpwM1NkNm53J-I5eNysGqABLOqVv'; // Replace with your PayPal sandbox client ID
  private clientSecret = 'EJdk16mGxF_q-QO39dhJ5WNbL_KOX8_mmK4Ro5ZKLQSaReSPEKeC4vbx20rAJ0-Hf-tihbIcx-6RlDka'; // Replace with your PayPal sandbox secret

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }


  loadPayPalScript() {
    const clientId = environment.payment.paypal.clientId; 
    if (this.document.getElementById('paypal-sdk')) {
      return;
    }
    const script = this.renderer.createElement('script');
    script.id = 'paypal-sdk';
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR`;
    script.defer = true;
    script.onload = () => {
      console.warn('PayPal SDK loaded successfully');
    };
    this.renderer.appendChild(this.document.head, script);
  }

  // Fetch order details
  getOrderDetails(paymentId): Observable<any> {
    const auth = btoa(`${this.clientId}:${this.clientSecret}`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${auth}`
    });

    return this.http.get(`https://api.sandbox.paypal.com/v2/checkout/orders/${paymentId}`, { headers });
  }

  setPaymentDetails(paymentDetails: any) {
      return this.apollo.mutate<any>({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation MyMutation($amount_in_eur: Float! $vat_amount: Float! $func_cd: String! $payment_id: String! $prev_bal: Float! $transaction_type: String! $status: String! $payment_method: String $payment_error: String $payment_reference: String $payment_reference_number: String, $vat_amount_in_eur: Float!) {
            addVcTransactionHistory(  
              input: {
                amount_in_eur: $amount_in_eur, 
                vat_amount: $vat_amount,
                func_cd: $func_cd, 
                payment_id: $payment_id,
                prev_bal: $prev_bal, 
                transaction_type: $transaction_type, 
                status: $status,
                payment_method: $payment_method,
                payment_error: $payment_error,
                payment_reference: $payment_reference,
                payment_reference_number: $payment_reference_number,
                vat_amount_in_eur: $vat_amount_in_eur
              }
            ){
              amount_in_eur
              cur_bal
              func_cd
              payment_id
              prev_bal
              timestamp
              transaction_type
              user_id
              status
              vino_coins
            }
          }`,
        variables: {
          amount_in_eur: paymentDetails.amount_in_eur, 
          vat_amount: paymentDetails.vat_amount, 
          cur_bal: paymentDetails.cur_bal, 
          func_cd: paymentDetails.func_cd, 
          payment_id: paymentDetails.payment_id,
          prev_bal: paymentDetails.prev_bal, 
          transaction_type: paymentDetails.transaction_type,
          status: paymentDetails.status,
          payment_method: paymentDetails.payment_method,
          payment_error: paymentDetails.payment_error,
          payment_reference: paymentDetails.payment_reference,
          payment_reference_number: paymentDetails.payment_reference_number,
          vat_amount_in_eur: paymentDetails.vat_amount_in_eur
        }
      });
    }
}
