<div class="home-container">
    <section class="section-one">
        <div class="background-image">
            <img class="home-banner d-none d-sm-block" src="../../assets/homepage/images/home-banner.png" alt="A table set with red wine glasses, wine bottles, and fresh grapes in a luxurious setting with purple accents." title="Luxurious Wine Table with Grapes and Bottles">
            <img class="home-banner d-sm-none" src="../../assets/homepage/images/home-banner-mobile.png" alt="A table set with red wine glasses, wine bottles, and fresh grapes in a luxurious setting with purple accents." title="Luxurious Wine Table with Grapes and Bottles">
        </div>
        <div class="banner-text">
            <h1 class="heading mb-3">
                {{ 'home.discover' | translate }}. {{ 'home.trade' | translate }}. {{ 'home.connect' | translate }}.
                <br>
                <i>{{ 'home.banner_title' | translate }}.</i>
            </h1>
            <p class="description mb-4">{{ 'home.banner_desc' | translate }}.</p>
            <button type="button" class="btn tr-btn-primary btn-signup" routerLink="/registration">{{ 'home.banner_sign_up' | translate }}</button>
        </div>
    </section>
    <section class="section-two">
        <div class="wine-slider-container container">
            <h4 class="heading primary-color">{{ 'home.section_two_title' | translate }}</h4>
            <p class="description">{{ 'home.section_two_desc' | translate }}</p>
            <carousel [itemsPerSlide]="itemsPerSlide" [showIndicators]="false" [interval]="0" [singleSlideOffset]="true" *ngIf="showCarousel">
                <slide *ngFor="let slide of wineSlides;">
                    <div class="wine-slide">
                        <div class="card-background">
                            <div class="wine-image">
                                <img [src]="slide.wineImage">
                            </div>
                            <div class="wine-details">
                                <span class="wine-name primary-color">{{ slide.wine_name }}</span>
                                <!-- <span class="wine-description">{{ slide.description }}</span> -->
                                <div class="button-container">
                                    <button type="button" class="btn tr-btn-primary">{{ 'home.buy_now' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
    <section class="section-three scroll-section" id="how-it-works">
        <div class="container">
            <h2 class="primary-color font-bold m-0">{{ 'home.section_three_title' | translate }}</h2>
            <img class="desktop-gif d-none d-md-block" src="../../assets/homepage/at-a-glance/at-a-glance-desktop.gif" alt="Visual diagram showing a user interacting with features like the Cellar Book, Dashboard, Marketplace, and Community, connected to Vino Coins for completing tasks, leveling up, and viewing bids and market prices." title="User Workflow and Earning Vino Coins on TresorVino">
            <img class="mobile-gif d-md-none" src="../../assets/homepage/at-a-glance/at-a-glance-mobile.gif" alt="Visual diagram showing a user interacting with features like the Cellar Book, Dashboard, Marketplace, and Community, connected to Vino Coins for completing tasks, leveling up, and viewing bids and market prices." title="User Workflow and Earning Vino Coins on TresorVino">
        </div>
    </section>
    <section class="section-four">
        <div class="container">
            <div class="video-container text-center">
                <div class="position-relative">
                    <video id="home-video" loop>
                        <source src="../../assets/homepage/vino-coins/vino-coins.mp4" type="video/mp4">
                    </video>
                    <div class="pause-overlay" *ngIf="!isVideoPlaying"></div>
                    <button type="button" class="play-pause-button" (click)="togglePlayPause()" *ngIf="!isVideoPlaying">
                        <img class="play-icon" src="../../assets/icons/play-icon.png">
                    </button>
                </div>
                <h4 class="mt-4">{{ 'home.section_four_video_title' | translate }}</h4>
                <p>{{ 'home.section_four_video_desc' | translate }}</p>
                <button type="button" class="btn tr-btn-primary" routerLink="/registration">{{ 'home.get_start' | translate }}</button>
            </div>
        </div>
    </section>
    <section class="section-five scroll-section" id="features">
        <div class="container position-relative">
            <h2 class="primary-color heading">{{ 'home.why_tresorvino' | translate }}</h2>
            <p class="description">{{ 'home.section_five_desc' | translate }}</p>
            <div class="position-relative">
                <div class="features-container">
                    <div class="feature">
                        <div class="feature-info" id="ft-dashboard">
                            <h3 class="feature-title">{{ 'home.feature_1_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_1_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="dashboardMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="dashboardDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/dashboard/dashboard-desktop.gif" alt="A dashboard showing the performance of all wine cellar books, including investment value, current value, total return, and a visual donut chart of wine types." title="Performance Overview of All Wine Cellar Books">
                                <img class="d-md-none" src="../../assets/homepage/dashboard/dashboard-mobile.gif" alt="A dashboard showing the performance of all wine cellar books, including investment value, current value, total return, and a visual donut chart of wine types." title="Performance Overview of All Wine Cellar Books">
                            </div> -->
                        </div>
                    </div>
                    <div class="feature">
                        <div class="feature-info" id="ft-vino">
                            <h3 class="feature-title">{{ 'home.feature_2_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_2_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="vinoMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="vinoDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/vino-coins/vino-coins-desktop.gif" alt="Interface showing an option to unlock bids with 30 Vino Coins, alongside tasks like adding wines to the Cellar Book, buying from the marketplace, and posting wines to earn more Vino Coins." title="Unlock Bids and Earn Vino Coins on TresorVino">
                                <img class="d-md-none" src="../../assets/homepage/vino-coins/vino-coins-mobile.gif" alt="Interface showing an option to unlock bids with 30 Vino Coins, alongside tasks like adding wines to the Cellar Book, buying from the marketplace, and posting wines to earn more Vino Coins." title="Unlock Bids and Earn Vino Coins on TresorVino">
                            </div> -->
                        </div>
                    </div>
                    <div class="feature">
                        <div class="feature-info" id="ft-wine-db">
                            <h3 class="feature-title">{{ 'home.feature_3_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_3_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="wineMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="wineDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/wine-database/wine-database-desktop.gif" alt="Detailed wine listing for Mont Redon Châteauneuf-du-Pape, featuring producer information, market price, quantity, and selling options." title="Mont Redon Châteauneuf-du-Pape Wine Listing">
                                <img class="d-md-none" src="../../assets/homepage/wine-database/wine-database-mobile.gif" alt="Detailed wine listing for Mont Redon Châteauneuf-du-Pape, featuring producer information, market price, quantity, and selling options." title="Mont Redon Châteauneuf-du-Pape Wine Listing">
                            </div> -->
                        </div>
                    </div>
                    <div class="feature">
                        <div class="feature-info" id="ft-p2p">
                            <h3 class="feature-title">{{ 'home.feature_4_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_4_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="p2pMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="p2pDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/p2p/p2p-desktop.gif" alt="A visual representation of users with different levels and badges, along with wine trading options like ‘Bid’ and ‘Sell’ highlighted below." title="User Levels and Wine Trading Options">
                                <img class="d-md-none" src="../../assets/homepage/p2p/p2p-mobile.gif" alt="A visual representation of users with different levels and badges, along with wine trading options like ‘Bid’ and ‘Sell’ highlighted below." title="User Levels and Wine Trading Options">
                            </div> -->
                        </div>
                    </div>
                    <div class="feature">
                        <div class="feature-info" id="ft-community">
                            <h3 class="feature-title">{{ 'home.feature_5_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_5_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="commMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="commDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/community/global-community-desktop.gif" alt="A private wine community group interface showing members and a discussion post about the best places to learn about wine making." title="Community Group for Wine Enthusiasts in Berlin">
                                <img class="d-md-none" src="../../assets/homepage/community/global-community-mobile.gif" alt="A private wine community group interface showing members and a discussion post about the best places to learn about wine making." title="Community Group for Wine Enthusiasts in Berlin">
                            </div> -->
                        </div>
                    </div>
                    <div class="feature asset-class">
                        <div class="feature-info" id="ft-asset">
                            <h3 class="feature-title">{{ 'home.feature_6_title' | translate }}</h3>
                            <p class="feature-description">{{ 'home.feature_6_desc' | translate }}</p>
                        </div>
                        <div class="feature-video">
                            <ng-lottie class="d-md-none" [options]="assetMobileOptions"></ng-lottie>
                            <!-- <div class="sticky-container">
                                <ng-lottie class="d-none d-md-block" [options]="assetDeskOptions" width="400"></ng-lottie>
                                <img class="d-none d-md-block" src="../../assets/homepage/asset-class/asset-class-desktop.gif" alt="Screenshot of a Digital Cellar Book interface showing detailed wine profiles, prices, and purchase history." title="Digital Cellar Book for Managing Wine Collections">
                                <img class="d-md-none" src="../../assets/homepage/asset-class/asset-class-mobile.gif" alt="Screenshot of a Digital Cellar Book interface showing detailed wine profiles, prices, and purchase history." title="Digital Cellar Book for Managing Wine Collections">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="desktop-lottie-section d-none d-md-block">
                    <div class="feature-video" id="ft-dashboard" [ngClass]="{'show-lottie': currentSection === 'ft-dashboard'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="dashboardDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                    <div class="feature-video" id="ft-vino" [ngClass]="{'show-lottie': currentSection === 'ft-vino'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="vinoDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                    <div class="feature-video" id="ft-wine-db" [ngClass]="{'show-lottie': currentSection === 'ft-wine-db'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="wineDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                    <div class="feature-video" id="ft-p2p" [ngClass]="{'show-lottie': currentSection === 'ft-p2p'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="p2pDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                    <div class="feature-video" id="ft-community" [ngClass]="{'show-lottie': currentSection === 'ft-community'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="commDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                    <div class="feature-video" id="ft-asset" [ngClass]="{'show-lottie': currentSection === 'ft-asset'}">
                        <div class="sticky-container">
                            <ng-lottie class="d-none d-md-block" [options]="assetDeskOptions" width="400"></ng-lottie>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section-six">
        <div class="position-relative">
            <div class="vinocoin-wave-banner"></div>
            <div class="content-wrapper d-flex flex-column flex-md-row">
                <div class="coin-pyramid">
                    <img src="../../assets/homepage/images/vino-coin-image.png" alt="A pyramid of Vino Coins with one coin prominently highlighted, symbolizing the in-app currency used for trading and rewards on the TresorVino platform." title="Vino Coins Highlight on TresorVino Platform">
                </div>
                <div class="pyramid-details">
                    <h5>{{ 'home.section_six_title' | translate }}</h5>
                    <p>{{ 'home.section_six_desc' | translate }}</p>
                    <button class="btn tr-btn-primary" routerLink="/registration">{{ 'home.banner_sign_up' | translate }}</button>
                </div>
            </div>
        </div>
        <div class="community-banner scroll-section" id="community">
            <div class="container d-flex flex-column flex-md-row">
                <div class="left-side">
                    <img class="star" src="../../assets/img/level-star.svg">
                    <h2>{{ 'home.community_banner_title' | translate }}</h2>
                    <p class="text-one secondary-color">{{ 'home.community_banner_desc' | translate }}</p>
                    <div class="divider-line"></div>
                    <div class="text-two">{{ 'home.community_banner_question' | translate }} <br><span class="secondary-color"> {{ 'home.community_banner_answer' | translate }}</span></div>
                    <button class="btn tr-btn-primary" routerLink="/registration">{{ 'home.get_start' | translate }}</button>
                </div>
                <div class="right-side">
                    <img class="image-two d-block d-md-none" src="../../assets/homepage/images/community-two.png">
                    <img class="image-one" src="../../assets/homepage/images/community-one.png">
                    <img class="image-two d-none d-md-block" src="../../assets/homepage/images/community-two.png">
                </div>
            </div>
            <div class="lower-rectangle">
                <img src="../../assets/homepage/images/community-rectangle.png" alt="rectangle">
            </div>
        </div>

    </section>
    <section class="section-seven">
        <div class="articles-slider-container container">
            <h4 class="heading primary-color text-center">{{ 'home.check_out' | translate }}</h4>
            <carousel [itemsPerSlide]="itemsPerSlide" [showIndicators]="false" *ngIf="showCarousel">
                <slide *ngFor="let article of wineArticles;">
                    <div class="article-slide">
                        <div class="card-background">
                            <div class="article-image">
                                <img [src]="article.imagePath">
                            </div>
                            <div class="article-details">
                                <span class="article-name primary-color">{{ article.title }}</span>
                                <span class="article-description">{{ article.description }}</span>
                                <div class="button-container">
                                    <button type="button" class="btn tr-btn-primary">{{ 'home.read' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
    <section class="section-eight scroll-section" id="faq">
        <div class="faq-wave-banner"></div>
        <div class="faq-section">
            <div class="container">
                <h2 class="heading">{{ 'home.any_question' | translate }} <i class="secondary-color">{{ 'home.we_got_you' | translate }}</i></h2>
                <div class="faq" *ngFor="let faq of mainFaqList; let i = index;">
                    <div class="question">
                        <div class="text">{{ faq.question }}</div>
                        <div class="icon" (click)="openAccordion(i)">
                            <mat-icon *ngIf="openFaqIndex !== i">add_2</mat-icon>
                            <mat-icon *ngIf="openFaqIndex === i">remove</mat-icon>
                        </div>
                    </div>
                    <div class="answer secondary-color" *ngIf="openFaqIndex === i" [innerHTML]="faq.answer"></div>
                </div>
                <div class="button-container text-center">
                    <button type="button" class="btn tr-btn-primary" routerLink="/faqs">{{ 'home.view_more' | translate }}</button>
                </div>
            </div>
        </div>
    </section>
</div>