import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-vino-coins',
  templateUrl: './vino-coins.component.html',
  styleUrls: ['./vino-coins.component.css']
})
export class VinoCoinsComponent implements OnInit {
  @Input() earnVcCount: any;
  @Input() earnVcDescription: any;
  @Output() onClosePopup = new EventEmitter();

  constructor( ) { }

  ngOnInit(): void {
  }

  closePopup(): void {
    this.onClosePopup.emit();
  }
}
