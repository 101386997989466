import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { AuthenticationService } from '../../sharedServices/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  submitted = false;
  forgotPasswordForm: FormGroup;
  userName: string;


  constructor(private route: Router, private formBuilder: FormBuilder,
    public translate: TranslateService,
    private data: AuthenticationService) { }

  ngOnInit(): void {
    this.data.currentUserName.subscribe(userName => this.userName = userName);
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  onForgotPassword(email: HTMLInputElement) {
    this.userName = this.forgotPasswordForm.value.email.toLowerCase();
    this.data.changeUserDetails(this.userName);
    Auth.forgotPassword(this.forgotPasswordForm.value.email.toLowerCase())
      .then(data => {
        this.route.navigate(["/reset-password"]);
        this.onReset();
      })
      .catch(err => {

        if (err.message == "Attempt limit exceeded, please try after some time.") {
          this.forgotPasswordForm.get('email')?.setErrors({ AttemptExceededWarning: true });
          this.route.navigate(["/login"]);
        }
        else if (err.message == "Username/client id combination not found.") {
          this.forgotPasswordForm.get('email')?.setErrors({ validEmailWarning: true });
        }
        else if (err.message == "Cannot reset password for the user as there is no registered/verified email or phone_number") {
          alert(this.translate.instant("login.email_verify_alert") + " " + this.userName);
          this.forgotPasswordForm.get('email')?.setErrors({ emailVerifyAlert: true });
          this.onReset();
          this.route.navigate(["/verify-account"]);
          Auth.resendSignUp(this.userName).then(() => {
            console.log('code resent successfully');
          }).catch(e => {
            console.log(e);
          });
        }
        else {
          alert(this.translate.instant("login.email_required_warning"));
        }
      });


  }
  onFPSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.onForgotPassword(this.forgotPasswordForm.value.email);
  }
  onReset() {
    this.submitted = false;
    this.forgotPasswordForm.reset();

  }
  get forgotPasswordDetails() {
    return this.forgotPasswordForm.controls;
  }

}
