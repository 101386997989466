/* eslint-disable lines-between-class-members */
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../sharedServices/authentication.service';
import UserDataService from 'src/app/sharedServices/user-data.service';
import { UserProfileDataService } from 'src/app/user-profile/user-profile-data.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { SharedApiService } from '../../services/shared-api.service';
import LoadingIndicatorService from 'src/app/sharedServices/loading-indicator.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export default class HeaderComponent implements OnInit {
  authStatus: boolean = false;
  isLoginPage: boolean = false;
  firstName: string;
  imageUrl: string;
  user: any = {};
  userLang = '';
  showMenu = window.innerWidth > 991;
  vinoCoinBalance = 0;
  onRegister: boolean = false;
  registerText = "Register";
  isFirstTime = true;
  userType = "Private User";
  isBusinessUser = false;
  userLevelInfo: any;
  tooltipVisible = false;
  challengeIndex = 0;
  allLevelDetails = [];
  showLevelProgress = false;
  showProfileMenu = false;
  runningTaskIndex = 0;
  isSuperUser: boolean = false;

  constructor(
    private router: Router,
    public amplifyService: AmplifyService,
    public translate: TranslateService,
    public data: AuthenticationService,
    private userDataService: UserDataService,
    private userProfileDataService: UserProfileDataService,
    private storageService: StorageService,
    private shareApiService: SharedApiService,
    private loadingIndicator: LoadingIndicatorService,
  ) {
    translate.addLangs(['EN', 'DE']);
    this.userLang = this.userDataService.getLanguage();
    if (!this.userLang) {
      this.userLang = 'EN';
      this.userDataService.setLanguage(this.userLang);
    }
    if (this.router.url === '/login') {
      this.isLoginPage = true;
      this.onRegister = true;
    }
    if (this.router.url === '/registration') {
      this.isLoginPage = false;
      this.onRegister = true;
    }
    this.translate.setDefaultLang(this.userLang);
    this.translate.use(this.userLang);
    this.registerText = (this.userLang == 'EN') ? "Register" : "Registrieren";
    Hub.listen('auth', async ({ }) => {
      const user = await Auth.currentAuthenticatedUser();
      this.user = user;
      if (user.attributes.hasOwnProperty('custom:languagePreference')) {
        this.translate.use(user.attributes['custom:languagePreference']);
        this.userLang = user.attributes['custom:languagePreference'];
        this.userDataService.setLanguage(user.attributes['custom:languagePreference']);
        this.translate.setDefaultLang(user.attributes['custom:languagePreference']);
      } else {
        this.translate.use('EN');
        this.translate.setDefaultLang('EN');
      }
      this.userType = this.user.attributes["custom:userType"];
      this.isBusinessUser = (this.userType == "Business User");
      if (user.attributes.hasOwnProperty('custom:superUserStatus') && user.attributes["custom:superUserStatus"] == 'true') {
        this.isSuperUser = true;
      }
    });

    const cookieInfo = this.storageService.getCookie('tresInfo');
    if (cookieInfo.length) {
      this.isFirstTime = false;
    } else {
      this.isFirstTime = true;
    }

    if (this.router.url === '/login') {
      this.isLoginPage = true;
      this.onRegister = true;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/home') {
          this.authStatus = false;
          this.isLoginPage = false;
          this.onRegister = false;
        }
        if (event.url === '/login') {
          this.isLoginPage = true;
          this.onRegister = true;
        }
        if (event.url === '/registration') {
          this.onRegister = true;
        }
        if (event.url === '/federated-user-details?firstTimeLogin=true') {
          this.authStatus = false;
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        this.showMenu = false;
        this.checkUserSession();
      }
    });
    this.checkUserSession();
    this.userProfileDataService.currentVinoCoins$.subscribe((data: any) => {
      if (data) {
        this.vinoCoinBalance = parseFloat(data);
      } else {
        this.vinoCoinBalance = 0;
      }
    });
  }

  ngOnInit(): void {
    this.data.currentImage.subscribe(imageUrl => (this.imageUrl = imageUrl));
    this.data.currentAuthStatus.subscribe((authStatus) => {
      this.authStatus = authStatus;
      if (this.authStatus) {
        this.getUserLevelInfo();
        this.userProfileDataService.setVinoCoins();
      }
      }
    );
    this.data.currentFirstName.subscribe(
      firstName => (this.firstName = firstName.toString())
    );
    this.data.currentUser.subscribe(user => (this.user = user));
    if (this.user.attributes) {
      if (this.user.attributes.hasOwnProperty('custom:userType') && this.user.attributes['custom:userType'] !== 'NA') {
        this.userType = this.user.attributes["custom:userType"];
        this.isBusinessUser = (this.userType == "Business User");
      } 
      if (this.user.attributes.hasOwnProperty('custom:superUserStatus') && this.user.attributes["custom:superUserStatus"] == 'true') {
        this.isSuperUser = true;
      }
    } else {
      console.log("check user attributes : " , this.user.attributes);
    }
    
    this.userDataService.userLevelUp$.subscribe((data: any) => {
      this.getUserLevelInfo();
    })
  }

  showHideMenu(): void {
    this.showMenu = !this.showMenu;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.userLang = lang;
    this.userDataService.setLanguage(this.userLang);
    if (this.authStatus) {
      Auth.updateUserAttributes(this.user, {
        'custom:languagePreference': this.userLang
      }).then(data => {}).catch(err => {
        console.log(err);
      });
    }
    this.registerText = (this.userLang == 'EN') ? "Register" : "Registrieren";
  }

  onLogOut() {
    Auth.signOut({ global: true })
      .then(data => {
        this.clearStorages();
        window.location.assign('/home');
        this.authStatus = false;
        this.isLoginPage = false;
      })
      .catch((err) => {
        console.log(err);
        if (err?.message === 'User is disabled.' || err?.message === 'Access Token has been revoked') {
          this.clearStorages();
          window.location.assign('/home');
          this.authStatus = false;
          this.isLoginPage = false;
        }
      });
  }

  checkUserSession(): void {
    Auth.currentSession()
      .then(data => {
        this.authStatus = true;
        if (this.router.url === '/federated-user-details?firstTimeLogin=true') {
          this.authStatus = false;
        }
      })
      .catch(err => {
        this.authStatus = false;
      });
  }

  clearStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserLevelInfo(): void {
    if (this.authStatus) {
      this.loadingIndicator.show();
      this.shareApiService.getUserLevelInfo().valueChanges.subscribe((response: any) => {
        this.userLevelInfo = response.data?.getUserLevelInformation;
        this.challengeIndex = parseInt(this.userLevelInfo?.user_level);
        this.runningTaskIndex = this.challengeIndex + 1;
        this.generateLevelInfoArray();
        this.userDataService.setUserLevelsInformation(this.userLevelInfo);
        this.loadingIndicator.hide();
      })
    }
  }

  generateLevelInfoArray(): void {
    this.allLevelDetails = [];
    for (let i = 1; i <= 24; i++) {
      this.allLevelDetails.push({...{level: i }, ...this.userLevelInfo['Level_'+i]});
    }
  }

  prevChallenge(): void {
    this.challengeIndex > 0 ? this.challengeIndex-- : this.challengeIndex = 0;
  }

  nextChallenge(): void {
    this.challengeIndex < 23 ? this.challengeIndex++ : this.challengeIndex = 23;
  }

  showHideLevelProgressOverlay(): void {
    this.showLevelProgress = !this.showLevelProgress;
  }

  showHideProfileMenu(): void {
    this.showProfileMenu = !this.showProfileMenu;
  }
}
