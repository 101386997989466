import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { JwPaginationModule } from 'jw-angular-pagination';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import FilterPipe from './pipes/filter.pipe';
import LoadingIndicatorComponent from './components/loading-indicator/loading-indicator.component';
import ShareComponent from './components/share/share.component';
import ListGridViewComponent from './components/list-grid-view/list-grid-view.component';
import CurrencySymbolPipe from './pipes/currency-symbol.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { WineStatusDirective } from './directives/wine-status.directive';
import { ConfirmationOverlayComponent } from './components/confirmation-overlay/confirmation-overlay.component';
import { WinePriceDirective } from './directives/wine-price.directive';
import { MaterialModule } from '../material.module';
import TableSortPipe from './pipes/table-sort.pipe';
import FilterStringPipe from './pipes/filter-string.pipe';
import SearchOwnWinePipe from './pipes/search-own-wine.pipe';
import { ToastComponent } from './components/toast/toast.component';
import { FaqComponent } from './components/faq/faq.component';
import { CarouselOverlayComponent } from './components/carousel-overlay/carousel-overlay.component';
import { WineImagePipe } from './pipes/wine-image/wine-image.pipe';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InfoOverlayComponent } from './components/info-overlay/info-overlay.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ParseAddressPipe } from './pipes/parse-address/parse-address.pipe';
import { SingleSelectDropdownComponent } from './components/single-select-dropdown/single-select-dropdown.component';
import { ViewUserReviewComponent } from '../view-user-review/view-user-review.component';
import { TimeDurationPipe } from '../shared/pipes/time-duration/time-duration.pipe';
import { LevelUpComponent } from './components/level-up/level-up.component';
import { VinoCoinsComponent } from './components/vino-coins/vino-coins.component';
import { SessionWarningPopupComponent } from './components/session-warning-popup/session-warning-popup.component';
import { VinoCoinBalanceComponent } from './components/vino-coin-balance/vino-coin-balance.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { FooterComponent } from './components/footer/footer.component';
import { PreloginHeaderComponent } from './components/prelogin-header/prelogin-header.component';
import { RouterModule } from '@angular/router';
import { PaypalPaymentComponent } from './components/paypal-payment/paypal-payment.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ListGridViewComponent,
    FilterPipe,
    LoadingIndicatorComponent,
    ShareComponent,
    CurrencySymbolPipe,
    WineStatusDirective,
    ConfirmationOverlayComponent,
    WinePriceDirective,
    WinePriceDirective,
    FilterStringPipe,
    SearchOwnWinePipe,
    TableSortPipe,
    ToastComponent,
    FaqComponent,
    CarouselOverlayComponent,
    WineImagePipe,
    InfoOverlayComponent,
    DateFormatPipe,
    ParseAddressPipe,
    SingleSelectDropdownComponent,
    ViewUserReviewComponent,
    TimeDurationPipe,
    LevelUpComponent,
    VinoCoinsComponent,
    SessionWarningPopupComponent,
    VinoCoinBalanceComponent,
    CustomTooltipComponent,
    FooterComponent,
    PreloginHeaderComponent,
    PaypalPaymentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    // JwPaginationModule,
    ClickOutsideModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    CarouselModule
  ],
  exports: [
    HttpClientModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    // JwPaginationModule,
    ClickOutsideModule,
    ListGridViewComponent,
    FilterPipe,
    LoadingIndicatorComponent,
    ShareComponent,
    CurrencySymbolPipe,
    TooltipModule,
    WineStatusDirective,
    ConfirmationOverlayComponent,
    WinePriceDirective,
    MaterialModule,
    FilterStringPipe,
    SearchOwnWinePipe,
    TableSortPipe,
    AlertModule,
    ToastComponent,
    CarouselOverlayComponent,
    WineImagePipe,
    InfoOverlayComponent,
    ParseAddressPipe,
    SingleSelectDropdownComponent,
    ViewUserReviewComponent,
    TimeDurationPipe,
    LevelUpComponent,
    VinoCoinsComponent,
    SessionWarningPopupComponent,
    VinoCoinBalanceComponent,
    CustomTooltipComponent,
    FooterComponent,
    PreloginHeaderComponent,
    CarouselModule,
    PaypalPaymentComponent
  ],
})
export default class SharedModule { }
